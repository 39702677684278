<template>
  <div class="travel-frequency-cell">
    <div class="titleArea">
      <van-row>
        <van-col offset="1" span="16" class="text van-ellipsis title">{{ data.customerName }}</van-col>
        <van-col offset="3" span="4" class="text van-ellipsis title"
                 style="font-size: 25px; font-weight: bolder; color: red">{{ data.bulkCrmReplayDTO.length }}
        </van-col>
      </van-row>
    </div>
    <div class="driver"></div>
    <div :class="data.bulkCrmReplayDTO.length==1?'textArea1':'textArea'" :data-radius=commonIndex>
      <van-row class="card" type="flex" justify="center" align="center" v-for="(item, index) in data.bulkCrmReplayDTO"
               v-bind:key="item.replayId"
               v-if="index<2"
      >
        <van-col span="7" class="progress">
          {{ item.travelDate }}
          <i v-if="item.use" class="line"></i>
        </van-col>
        <van-col offset="1" span="12" @click="to(item)">
          <van-row>
            <van-col span="24" class="text van-ellipsis title">{{ item.createdPeople }}→{{ item.realContact }}</van-col>
          </van-row>
          <van-row>
            <van-col span="7" class="text van-ellipsis info">
              {{ item.travelType }}
            </van-col>
<!--            <van-col offset="1" span="9" class="text van-ellipsis info">-->
<!--              {{ item.travelDate }}-->
<!--            </van-col>-->
            <!--            <van-col offset="1" span="6" class="text van-ellipsis info">-->
            <!--              {{ item.createdPeople }}-->
            <!--            </van-col>-->
          </van-row>
        </van-col>
        <van-col offset="1" span="3">
          <van-icon :name="item.moreBtn?'arrow-down':''" v-if="index+1==2 && data.bulkCrmReplayDTO.length>2"
                    @click="moreInfo(item, index)"/>
        </van-col>
      </van-row>
    </div>
    <div class="collapse-item" v-if="!moreBtn"
         :style="{height: freshHeight + (data.bulkCrmReplayDTO.length - 2)*50+25   +'px'}">
      <van-row class="card" type="flex" justify="center" align="center" v-for="(item, index) in data.bulkCrmReplayDTO"
               v-bind:key="item.replayId"
               v-if="index>=2">
        <van-col span="7" class="progress">
          {{ item.travelDate }}
          <i v-if="item.use" class="line"></i>
        </van-col>
        <van-col offset="1" span="12" @click="to(item)">
          <van-row>
            <van-col span="24" class="text van-ellipsis title">{{ item.createdPeople }}→{{ item.realContact }}</van-col>
          </van-row>
          <van-row>
            <van-col span="8" class="text van-ellipsis info">
              {{ item.travelType }}
            </van-col>
<!--            <van-col offset="1" span="7" class="text van-ellipsis info">-->
<!--              {{ item.travelDate }}-->
<!--            </van-col>-->
            <!--            <van-col offset="1" span="7" class="text van-ellipsis info">-->
            <!--              {{ item.createdPeople }}-->
            <!--            </van-col>-->
          </van-row>
        </van-col>
        <van-col offset="1" span="3">
          <van-icon :name="moreBtn?'':'arrow-up'"
                    v-if="index+1==data.bulkCrmReplayDTO.length && data.bulkCrmReplayDTO.length>2"
                    :data-radius=commonIndex
                    @click="moreInfo1(item, index, $event)"/>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      freshHeight: 0,
      moreBtn: true,
    }
  },
  props: {
    data: Object,
    commonIndex: Number,
  },
  mounted() {
  },
  methods: {
    to(item) {
      this.$router.push('/BulkCrmsVisitReplayDetailEdit/' + item.replayId);
    },
    moreInfo(item, index) {
      this.moreBtn = !this.moreBtn
      item.use = !item.use
      item.moreBtn = !item.moreBtn
    },
    moreInfo1(item, index, e) {
      console.log(e.target.dataset.radius)
      this.moreBtn = !this.moreBtn
      this.$emit("comIndex", e.target.dataset.radius);
    },
  },
}
</script>

<style scoped>
.travel-frequency-cell {
  background-color: #EFF1F5;
  padding: 15px 15px 15px 15px;
}

.travel-frequency-cell .titleArea {
  padding-top: 5px;
  background-color: #EEF4FF;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 30px;
  font-weight: bold;
  font-family: 华文楷体;
}

.travel-frequency-cell .textArea1 {
  height: 60px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #FDFFFF;
}


.travel-frequency-cell .textArea {
  height: 110px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #FDFFFF;
}

.travel-frequency-cell .card {
  padding-top: 5px;
}

.travel-frequency-cell .progress {
  position: relative;
  font-weight: 700;
  font-size: 14px;
  color: #101010;
  font-style: normal;
  letter-spacing: 0;
  line-height: 24px;
  text-decoration: none;
}

.travel-frequency-cell .progress i.line:nth-last-of-type(1) {
  display: block;
  position: absolute;
  height: 30px;
  border-left: 1px solid;
  left: 50%;
  color: #5b6b73;
}


.travel-frequency-cell .text {
  text-align: left;
  font-weight: bold;
}

.travel-frequency-cell .title {
  font-weight: 400;
  font-size: 15px;
  color: #111111;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
  font-family: 微软雅黑;
}

.travel-frequency-cell .info {
  font-weight: 400;
  font-size: 12px;
  color: #827E7E;
  font-style: normal;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
  font-family: 微软雅黑;
}

.travel-frequency-cell .icon {
  line-height: 24px;
}

.travel-frequency-cell .driver {
  height: 10px;
  background-image: linear-gradient(to right, #CFDEFF, #B7D3FF, #7BB9FF);
  overflow: hidden;
}

.travel-frequency-cell .collapse-item {
  background-color: #FDFFFF;
  margin-top: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

</style>