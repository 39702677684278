<template>
  <div class="travel-frequency-panel" :style="{height: freshHeight +'px', boxSizing: 'border-box'}">
    <van-tabs v-model="activeName" @click="onClick" sticky>
      <van-tab title="客户" name="a" style="overflow-y: auto;">
        <scroll-lock>
          <van-row style="background-color: #0084F8">
            <van-col span="24">
              <van-search v-model="selectCustomerVal" :focus="theFocus" custom-class="inp" placeholder="请输入客户名称"
                          show-action
                          @cancel="onCancel()" @search="init()"/>
            </van-col>
          </van-row>
          <van-row span="24">
            <div v-for="(item, index) in timeSpan" v-bind:key="item.id" :data="item">
              <van-col offset="1" span="5">
                <div class="timeSpan" @click="selectTime(item,index,'CUS')" :value="date"
                     :style="{color: item.use ? item.color : '#827E7E', background: item.use ? item.backColor: '#F3F3F3'}">
                  {{ item.title }}
                </div>
              </van-col>
            </div>
          </van-row>
        </scroll-lock>
        <van-calendar v-model="show"
                      :min-date="minDate"
                      :max-date="maxDate"
                      position="top" type="range" @confirm="onConfirm"/>
        <div v-if="this.list<=0" ref="childRef">
          <van-empty image="error" description="没有数据"/>
        </div>
        <div v-else ref="childRef" :style="{height: freshHeight +'px',overflowY: 'scroll', boxSizing: 'border-box'}">
          <travel-frequency-cell :style="{marginBottom: index==list.length-1 ? '45px' : '0px'}"
                                 v-for="(item,index) in list" v-bind:key="item.replayId" :data="item"
                                 :commonIndex="index"
                                 @comIndex="comIndex(arguments)"></travel-frequency-cell>
        </div>
      </van-tab>
      <van-tab title="负责人" name="b">
        <scroll-lock>
          <van-row style="background-color: #0084F8">
            <van-col span="24">
              <van-search v-model="selectFzrVal" :focus="theFocus" custom-class="inp" placeholder="请输入负责人名称" show-action
                          @cancel="onCancel1()" @search="init()"/>
            </van-col>
          </van-row>
          <van-row span="24">
            <div v-for="(item, index) in timeSpan1" v-bind:key="item.id" :data="item">
              <van-col offset="1" span="5">
                <div class="timeSpan" @click="selectTime(item,index, 'FZR')" :value="date1"
                     :style="{color: item.use ? item.color : '#827E7E', background: item.use ? item.backColor: '#F3F3F3'}">
                  {{ item.title }}
                </div>
              </van-col>
            </div>
          </van-row>
        </scroll-lock>
        <van-calendar v-model="show1"
                      :min-date="minDate"
                      :max-date="maxDate"
                      position="top" type="range" @confirm="onConfirm1"/>
        <div v-if="this.list1<=0" ref="childRef">
          <van-empty image="error" description="没有数据"/>
        </div>
        <div v-else ref="childRef" :style="{height: freshHeight +'px',overflowY: 'scroll', boxSizing: 'border-box'}">
          <travel-frequency-cell1 :style="{marginBottom: index==list1.length-1 ? '45px' : '0px'}"
                                  v-for="(item,index) in list1" v-bind:key="item.replayId" :data="item"
                                  @comIndex1="comIndex1(arguments)"></travel-frequency-cell1>
        </div>
      </van-tab>
      <van-tab title="区域" name="c">
        <van-row style="background-color: #0084F8">
          <van-col span="24">
            <van-search v-model="selectAreaVal" :focus="theFocus" custom-class="inp" placeholder="请输入区域名称" show-action
                        @cancel="onCancel()" @search="init()"/>
          </van-col>
        </van-row>
        <van-row span="24">
          <div v-for="(item, index) in timeSpan2" v-bind:key="item.id" :data="item">
            <van-col offset="1" span="5">
              <div class="timeSpan" @click="selectTime(item,index,'AREA')" :value="date2"
                   :style="{color: item.use ? item.color : '#827E7E', background: item.use ? item.backColor: '#F3F3F3'}">
                {{ item.title }}
              </div>
            </van-col>
          </div>
        </van-row>
        <van-calendar v-model="show2" position="top" type="range" @confirm="onConfirm2"/>
        <div v-if="this.list2>0">
        </div>
        <div v-else>
          <van-empty image="error" description="没有数据"/>
        </div>
      </van-tab>
    </van-tabs>
    <crm-tab-bar :tabpage="5"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import TravelFrequencyCell from "@/components/TravelFrequencyCell/index";
import TravelFrequencyCell1 from "@/components/TravelFrequencyCell/index1";
import {Lazyload} from "vant";

export default {
  name: "TravelFrequencyPanel",
  components: {
    CrmTabBar, Lazyload, TravelFrequencyCell, TravelFrequencyCell1
  },
  watch: {
    //监听客户名头
    'selectCustomerVal': function () {
      this.list = [];
      this.init();
    },
    //监听负责人名头
    'selectFzrVal': function () {
      this.list1 = [];
      this.init1();
    },
  },
  data() {
    return {
      minDate: '',
      maxDate: new Date(2030, 12, 31),
      freshHeight: 0,
      date: '',
      date1: '',
      date2: '',
      show: false,
      show1: false,
      show2: false,
      activeName: '',
      selectCustomerVal: '',
      selectFzrVal: '',
      selectAreaVal: '',
      theFocus: false,
      showGroupPicker: false,
      showUsersPicker: false,
      selGroupValue: '',
      selUserValue: '',
      // 点击的是哪条记录
      index: 0,
      timeSpan: [
        {
          title: '当年',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: new Date().getFullYear(),
          use: false,
        },
        {
          title: '当季度',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: '',
          use: false,
        },
        {
          title: '当月',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: new Date().getMonth() + 1,
          use: false,
        },
        {
          title: '自定义',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: '',
          use: false,
        },
      ],
      timeSpan1: [
        {
          title: '当年',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: new Date().getFullYear(),
          use: false,
        },
        {
          title: '当季度',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: '',
          use: false,
        },
        {
          title: '当月',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: new Date().getMonth() + 1,
          use: false,
        },
        {
          title: '自定义',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: '',
          use: false,
        },
      ],
      timeSpan2: [
        {
          title: '当年',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: new Date().getFullYear(),
          use: false,
        },
        {
          title: '当季度',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: '',
          use: false,
        },
        {
          title: '当月',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: new Date().getMonth() + 1,
          use: false,
        },
        {
          title: '自定义',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: '',
          use: false,
        },
      ],
      list: [],
      list1: [],
      list2: [],
      curYear: '',
      curQuarter: '',
      curMonth: '',
      custom: '',
      curYear1: '',
      curQuarter1: '',
      curMonth1: '',
      custom1: '',
      curYear2: '',
      curQuarter2: '',
      curMonth2: '',
      custom2: '',
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight
    this.init()
    var curDate = (new Date()).getTime()
    // 将半年的时间单位换算成毫秒
    var halfYear = 365 / 2 * 24 * 3600 * 1000
    // 半年前的时间（毫秒单位）
    var pastResult = curDate - halfYear
    // 日期函数，定义起点为半年前
    this.minDate = new Date(pastResult)
  },
  methods: {
    init() {
      this.$ajax.post('/api/ajax/progress/getProgressRecordListByCustomer.json',
          {
            customerName: this.selectCustomerVal,
            curYear: this.curYear,
            curQuarter: this.curQuarter,
            curMonth: this.curMonth,
            custom: this.custom,
          }).then(res => {
        if (res.status == 200) {
          this.list = res.data
          console.log(this.list)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('查询失败！');
      })
    },
    init1() {
      this.$ajax.post('/api/ajax/progress/getProgressRecordListByFzr.json',
          {
            createdPeople: this.selectFzrVal,
            curYear: this.curYear1,
            curQuarter: this.curQuarter1,
            curMonth: this.curMonth1,
            custom: this.custom1,
          }).then(res => {
        if (res.status == 200) {
          this.list1 = res.data
          console.log(this.list1)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('查询失败！');
      })
    },
    onCancel() {
      this.clearTime('CUS')
      this.init();
    },
    onCancel1() {
      this.clearTime('FZR')
      this.init1();
    },
    selectTime(item, index, name) {
      this.clearTime(name)
      item.use = true;
      this.setIndex(item, index, name)
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      this.custom = ''
      const [start, end] = date;
      this.show = false;
      this.date = `${this.formatDate(start)}至${this.formatDate(end)}`;
      console.log(this.date)
      this.custom = this.date
      this.init()
    },
    onConfirm1(date) {
      this.custom1 = ''
      const [start, end] = date;
      this.show1 = false;
      this.date1 = `${this.formatDate(start)}至${this.formatDate(end)}`;
      console.log(this.date1)
      this.custom1 = this.date1
      this.init1()
    },
    onConfirm2(date) {
      const [start, end] = date;
      this.show2 = false;
      this.date2 = `${this.formatDate(start)}-${this.formatDate(end)}`;
      console.log(this.date2)
      for (let i = 0; i < this.timeSpan2.length; i++) {
        this.timeSpan[i].use = false;
        this.timeSpan1[i].use = false;
        this.timeSpan2[i].use = false;
      }
    },
    onClick(name, title) {
      console.log(name + "****" + title)
      if (name == 'b') {
        this.init1()
      }
      if (name == 'a') {
        this.init()
      }
      for (let i = 0; i < this.timeSpan.length; i++) {
        this.timeSpan[i].use = false;
        this.timeSpan1[i].use = false;
        this.timeSpan2[i].use = false;
      }
    },
    comIndex(index) {
      console.log(index[0])
      this.list[index[0]].bulkCrmReplayDTO[1].use = !this.list[index[0]].bulkCrmReplayDTO[1].use
      this.list[index[0]].bulkCrmReplayDTO[1].moreBtn = true
    },
    comIndex1(index) {
      console.log(index[0])
      this.list1[index[0]].bulkCrmReplayDTO[1].use = !this.list1[index[0]].bulkCrmReplayDTO[1].use
      this.list1[index[0]].bulkCrmReplayDTO[1].moreBtn = true
    },
    clearTime(name) {
      if (name == 'CUS') {
        for (let i = 0; i < this.timeSpan.length; i++) {
          this.timeSpan[i].use = false;
        }
        this.curYear = '';
        this.curQuarter = '';
        this.curMonth = '';
        this.custom = '';
      } else if (name == 'FZR') {
        for (let i = 0; i < this.timeSpan1.length; i++) {
          this.timeSpan1[i].use = false;
        }
        this.curYear1 = '';
        this.curQuarter1 = '';
        this.curMonth1 = '';
        this.custom1 = '';
      } else {
        for (let i = 0; i < this.timeSpan1.length; i++) {
          this.timeSpan2[i].use = false;
        }
        this.curYear2 = '';
        this.curQuarter2 = '';
        this.curMonth2 = '';
        this.custom2 = '';
      }
    },
    setIndex(item, index, name) {
      if (name == 'CUS') {
        switch (index) { //想要判断的变量
          case 0: //状态一
            this.curYear = item.value
            this.init()
            break
          case 1: //状态二
            this.curQuarter = 'yes'
            this.init()
            break
          case 2://状态三
            this.curMonth = item.value
            this.init()
            break
          case 3:
            this.show = true
            break
        }
      } else if (name == 'FZR') {
        switch (index) { //想要判断的变量
          case 0: //状态一
            this.curYear1 = item.value
            this.init1()
            break
          case 1: //状态二
            this.curQuarter1 = 'yes'
            this.init1()
            break
          case 2://状态三
            this.curMonth1 = item.value
            this.init1()
            break
          case 3:
            this.show1 = true
            break
        }
      } else {
        switch (index) { //想要判断的变量
          case 0: //状态一
            this.curYear2 = item.value
            break
          case 1: //状态二
            this.curQuarter2 = 'yes'
            break
          case 2://状态三
            this.curMonth2 = item.value
            break
          case 3:
            this.show2 = true
            break
        }
      }
    }
  },
}
</script>

<style scoped>
.travel-frequency-panel {
  background-color: #FFFFFF;
}

.travel-frequency-panel .collapse {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 5px;
  margin: 1px;
}

.travel-frequency-panel .name-row {
  padding: 10px 2px;
  font-size: 25px;
  background: #EBF8FD;
}

.travel-frequency-panel .row-one {
  font-size: 12px;
  background: #EBF8FD;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.travel-frequency-panel .row-two {
  font-size: 12px;
  background: #FFFFFF;
  padding: 10px 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.travel-frequency-panel .company-info-bk {
  width: 100%;
  height: 150px;
}

.travel-frequency-panel .timeSpan {
  padding-top: 5px;
  width: 70px;
  height: 30px;
  background-color: #F3F3F3;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  vertical-align: middle
}
</style>

<style>
.travel-frequency-panel .van-tab {
  color: #FFF7FD;
  font-weight: 500;
  background-color: #0084F8;
}

</style>
